import clsx from "clsx";
import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import Icon, { IconProps } from "../../../Foundation/Icon/Icon";
import Typography, {
  ITypographyProps,
} from "../../../Foundation/Typography/Typography";
import { useTheme } from "../../../Themes/defaultTheme";
import FlexBox from "../../FlexBox/FlexBox";
import {
  _AccordionItemHeader,
  _AccordionItemPanel,
  _AccordionItemRoot,
} from "./AccordionItem.styled";

export enum EAccordionActiveShouldUpdate {
  NEVER = 0,
  ON_TRUE = 1,
  ON_FALSE = 2,
  ALWAYS = 3,
}
export interface IAccordionItemProps {
  header: ReactNode;
  children: ReactNode;
  className?: string;
  active?: boolean;
  first?: boolean;
  innerPadding?: CSSProperties["padding"];
  style?: (args: { isActive: boolean }) => CSSProperties;
  borderColor?: string;
  activeShouldUpdate?: EAccordionActiveShouldUpdate;
  headerConfig?: {
    iconProps?: Partial<IconProps>;
    titleAs?: ITypographyProps["as"];
    /** @default true */
    clickable?: boolean;
  };
  renderIcon?: (args: { isActive: boolean; toggleActive(): void }) => ReactNode;
  variant?: "default" | "beige" | "beige-active" | "beige-disabled";
}

const AccordionItem = (props: IAccordionItemProps) => {
  const {
    header,
    children,
    active = false,
    activeShouldUpdate = EAccordionActiveShouldUpdate.NEVER,
    first,
    innerPadding,
    borderColor,
    headerConfig,
    className,
    variant = "default",
    style,
    renderIcon,
  } = props;
  const {
    iconProps,
    titleAs,
    clickable: headerClickable = true,
  } = headerConfig || {};
  const { color } = useTheme();
  const [isActive, setIsActive] = useState<boolean>(active);

  const handleHeaderClick = () => {
    setIsActive((v) => !v);
  };

  useEffect(() => {
    const shouldUpdate =
      activeShouldUpdate === EAccordionActiveShouldUpdate.ALWAYS ||
      (activeShouldUpdate === EAccordionActiveShouldUpdate.ON_FALSE &&
        !active) ||
      (activeShouldUpdate === EAccordionActiveShouldUpdate.ON_TRUE && active);
    shouldUpdate && setIsActive(active);
  }, [active]);

  const rootStyle =
    typeof style === "function" ? style({ isActive }) : undefined;

  return (
    <_AccordionItemRoot
      $first={first}
      $borderColor={borderColor}
      $variant={variant}
      className={clsx("ro-accordion-item", { active: isActive }, className)}
      style={rootStyle}
    >
      <_AccordionItemHeader
        className={"ro-accordion-item__header"}
        onClick={headerClickable ? handleHeaderClick : undefined}
      >
        <FlexBox align={"center"}>
          <FlexBox flex={1}>
            {typeof header === "string" ? (
              <Typography as={titleAs} variant="h7" color="blackIcon">
                {header}
              </Typography>
            ) : (
              header
            )}
          </FlexBox>
          {renderIcon ? (
            renderIcon({
              isActive,
              toggleActive: handleHeaderClick,
            })
          ) : (
            <Icon
              icon="arrowhead_down"
              className="icon-arrowhead"
              color={color.blackIcon}
              {...iconProps}
            />
          )}
        </FlexBox>
      </_AccordionItemHeader>

      <_AccordionItemPanel className={clsx({ active: isActive })}>
        <div style={{ padding: innerPadding || "8px 0 16px" }}>
          {typeof children === "string" ? (
            <Typography variant="body" color="greyText">
              {children}
            </Typography>
          ) : (
            children
          )}
        </div>
      </_AccordionItemPanel>
    </_AccordionItemRoot>
  );
};

export default AccordionItem;
