import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductPageViewProps } from "@src/backbone/view/ProductPageView/ProductPageView";
import { ICartData, ICartItem } from "@src/interface/Cart";
import { IStoreModifier } from "@src/interface/Store";
import { IPreorderOptions } from "./cart.interface";
import { CartDeliveryOptions } from "@src/backbone/model/CartModel";

interface CartState {
  items: ICartItem[];
  isLoadingCart: boolean;
  cartLoaded: boolean;
  currentCart: ICartData;
  currentStep: 1 | 2;
  open: boolean;
  stockoutProduct: { id_store?: string; products?: string[] };
  modifiers?: IStoreModifier;
  openProduct?: null | IProductPageViewProps;
  openDeliveryOptions: boolean;
  openCheckoutAddressDatas: boolean;
  deliveryOptionsPreset?: Partial<CartDeliveryOptions>;
  preorder?: IPreorderOptions;
  hideOrderTracking?: boolean;
  hideTopBanner?: boolean;
  hideTopDeliveryStatus?: boolean;
  showAddAddress?: boolean;
}

const initialState: CartState = {
  items: [],
  isLoadingCart: false,
  cartLoaded: false,
  currentStep: 1,
  currentCart: { id: "", id_cart: "current" },
  open: false,
  stockoutProduct: {},
  modifiers: undefined,
  openDeliveryOptions: false,
  openCheckoutAddressDatas: false,
  showAddAddress: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCurrentCart(state, action: PayloadAction<Partial<ICartData>>) {
      if (state.currentCart) {
        state.currentCart = {
          ...state.currentCart,
          ...action.payload,
        };
      }
      // generateItems
      if (state.currentCart.products) {
        if (state.currentCart.products) {
          state.items = state.currentCart.products.map<ICartItem>(
            (product) => ({
              ...product,
            })
          );
        } else {
          state.items = [];
        }
      }
      return state;
    },
    setOpenCurrentCart(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
      state.currentStep = 1;
      return state;
    },
    closeCurrentCart(state) {
      state.open = false;
      return state;
    },
    setOpenSidedishesCart(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
      state.currentStep = 2;
      return state;
    },
    setCartStep(state, action: PayloadAction<1 | 2>) {
      state.currentStep = action.payload;
      return state;
    },
    setStockoutProduct(
      state,
      action: PayloadAction<{ id_store: string; products: string[] }>
    ) {
      state.stockoutProduct = { ...action.payload };
      return state;
    },
    setModifiers(state, action: PayloadAction<IStoreModifier>) {
      state.modifiers = { ...action.payload };
      return state;
    },
    setIsLoadingCart(state, action: PayloadAction<boolean>) {
      state.isLoadingCart = action.payload;
      return state;
    },
    setCartLoaded(state, action: PayloadAction<boolean>) {
      state.cartLoaded = action.payload;
      return state;
    },
    openProductPage(state, action: PayloadAction<IProductPageViewProps>) {
      state.openProduct = action.payload;
      return state;
    },
    closeProductPage(state, action: PayloadAction<void>) {
      state.openProduct = null;
      return state;
    },
    openDeliveryOptions(
      state,
      action: PayloadAction<Partial<CartDeliveryOptions> | undefined>
    ) {
      state.openDeliveryOptions = true;
      state.deliveryOptionsPreset = action.payload;
      return state;
    },
    closeDeliveryOptions(state, action: PayloadAction<void>) {
      state.openDeliveryOptions = false;
      state.deliveryOptionsPreset = undefined;
      return state;
    },
    toggleCheckoutAddressDatas(state, action: PayloadAction<boolean>) {
      state.openCheckoutAddressDatas = action.payload;
      return state;
    },
    openPreorder(state, action: PayloadAction<IPreorderOptions>) {
      state.preorder = action.payload;
      return state;
    },
    closePreorder(state, action: PayloadAction<void>) {
      state.preorder = undefined;
      return state;
    },
    hideOrderTracking(state, action: PayloadAction<void>) {
      state.hideOrderTracking = true;
      return state;
    },
    showOrderTracking(state, action: PayloadAction<void>) {
      state.hideOrderTracking = false;
      return state;
    },
    hideTopBanner(state, action: PayloadAction<void>) {
      state.hideTopBanner = true;
      return state;
    },
    showTopBanner(state, action: PayloadAction<void>) {
      state.hideTopBanner = false;
      return state;
    },
    hideTopDeliveryStatus(state, action: PayloadAction<void>) {
      state.hideTopDeliveryStatus = true;
      return state;
    },
    showTopDeliveryStatus(state, action: PayloadAction<void>) {
      state.hideTopDeliveryStatus = false;
      return state;
    },
    setShowAddAddress(state, action: PayloadAction<boolean>) {
      state.showAddAddress = action.payload;
      return state;
    },
  },
});

const reducer = cartSlice.reducer;

export const {
  updateCurrentCart,
  setOpenCurrentCart,
  closeCurrentCart,
  setOpenSidedishesCart,
  setCartStep,
  setIsLoadingCart,
  setCartLoaded,
  toggleCheckoutAddressDatas,
} = cartSlice.actions;
export default reducer;
