import React, { ReactNode } from "react";
import { CSSProperties } from "styled-components";
import Typography from "../../Foundation/Typography/Typography";
import { _TagRoot } from "./Tag.styled";
import { defaultTheme } from "../../Themes/defaultTheme";
import { ITypographyVariants } from "../../Foundation/Typography/Typography.styles";

export interface ITagProps {
  children: ReactNode;
  size?: "small" | "medium";
  variant?:
    | "yellow"
    | "dark"
    | "white"
    | "product-primary"
    | "product-secondary"
    | "selection-default"
    | "selection-danger"
    | "quantity"
    | "order-quantity";
  onClick?: () => void;
  style?: CSSProperties;
  id?: string;
}

const productVariantBase: CSSProperties = {
  borderRadius: 4,
  padding: "6px 12px",
  minHeight: 26,
  height: 26,
  alignItems: "baseline",
};
const selectionVariantBase: CSSProperties = {
  minHeight: 24,
  height: 24,
  padding: "4px 8px",
};

export const mapVariant: Record<
  NonNullable<ITagProps["variant"]>,
  CSSProperties
> = {
  yellow: {
    background: defaultTheme.color.yellow,
    color: "white",
  },
  dark: {
    background: defaultTheme.color.champagne7,
    color: "white",
  },
  white: {
    border: `1px solid #D8D8D8`,
    background: "white",
    color: defaultTheme.color.champagne7,
  },
  "selection-default": {
    background: "#F4F3F1",
    color: "#6C6C6C",
    ...selectionVariantBase,
  },
  "selection-danger": {
    background: "#FCE6E6",
    color: "#E60000",
    ...selectionVariantBase,
  },
  "product-primary": {
    background: defaultTheme.color.champagne3,
    color: defaultTheme.color.white,
    ...productVariantBase,
  },
  "product-secondary": {
    background: defaultTheme.color.productTagSecondary,
    color: defaultTheme.color.blackIcon,
    ...productVariantBase,
  },
  quantity: {
    background: defaultTheme.color.white,
    color: defaultTheme.color.blackIcon,
    borderRadius: 64,
    height: 36,
    minHeight: 36,
    padding: "5px 18px 5px 18px",
  },
  "order-quantity": {
    background: "#E8E8E8",
    color: defaultTheme.color.champagne7,
    borderRadius: 24,
    height: 32,
    minHeight: 32,
    padding: "7px 16px 8px 16px",
  },
};

const fontVariant: Record<
  NonNullable<ITagProps["variant"]>,
  ITypographyVariants
> = {
  yellow: "button2",
  dark: "button2",
  white: "button2",
  "product-primary": "ui",
  "product-secondary": "ui",
  "selection-default": "ui3",
  "selection-danger": "ui3",
  quantity: "body2",
  "order-quantity": "body2",
};

const Tag = (props: ITagProps) => {
  const {
    children,
    size = "small",
    style,
    variant = "yellow",
    onClick,
    id,
  } = props;
  return (
    <_TagRoot
      style={style}
      id={id}
      $size={size}
      $variant={variant}
      onClick={onClick}
    >
      <Typography
        variant={fontVariant[variant]}
        color={() => mapVariant[variant].color || "black"}
        fixVerticalAlign
        style={{ whiteSpace: "nowrap" }}
      >
        {children}
      </Typography>
    </_TagRoot>
  );
};

export default Tag;
