import * as React from "react";
import { GatsbyBrowser } from "gatsby";
import DefaultLayout, {
  IDefaultLayoutProps,
} from "../../src/layout/DefaultLayout/DefaultLayout";

export default (({ element, props }) => {
  const { pageContext } = props;
  const pageIntl: any = pageContext?.pageIntl || {};

  const pageTemplate: "Category" | string =
    (pageContext?.pageTemplate as string) || "";
  let wrapper: IDefaultLayoutProps["wrapper"];

  if (pageTemplate === "Category") {
    wrapper = "listing";
  }
  if (pageTemplate === "Home") {
    wrapper = "home";
  }

  // international page layout
  if (pageIntl?.layout === "empty") {
    return <>{element}</>;
  }
  return <DefaultLayout wrapper={wrapper}>{element}</DefaultLayout>;
}) as GatsbyBrowser["wrapPageElement"];
