import { navigate as intlSushishopNavigate } from "gatsby-plugin-intl-sushishop";
import { graphql, navigate as GatsbyNavigate, useStaticQuery } from "gatsby";
import { useAppStore, useFrontControllerSelector } from "@src/store/hooks";
import { startTransition, useCallback, useEffect, useMemo } from "react";
import queryString from "query-string";
import { useConfiguration } from "@src/hooks/query/useConfiguration";
import { useLazyGetHybridRoutingQuery } from "@src/services/rollingStartApi";
import {
  GATSBY_CONF_HOME_DISPLAY_OTHER_CONTRY,
  GATSBY_SOURCE_SUSHISHOP_BASE_URL,
} from "@src/utils/constants";
import useBackboneContext from "@src/backbone/provider/useBackboneContext";

type NavigateType = typeof GatsbyNavigate;

// runtime RollingStart routing
const _EXPERIMENTAL_ROLLING_START_ROUTING_ =
  typeof window !== "undefined" &&
  window?.location.hash === "#_EXPERIMENTAL_ROLLING_START_ROUTING_";

export interface IRollingStartNavigation {
  navigate: NavigateType;
  goBack: () => void;
  isRollingStart: (path: string) => boolean;
  getMatchedController: (path: string) => string | undefined;
}

const useRollingStartNavigation = (): IRollingStartNavigation => {
  // const [fetchHybridRouting, { data }] = useLazyGetHybridRoutingQuery();
  const { app } = useBackboneContext();
  // const { selectAll } = useFrontControllerSelector();

  // useEffect(() => {
  //   startTransition(() => {
  //     fetchHybridRouting();
  //   });
  // }, []);

  const hybridRoutingCollection = useMemo(() => {
    return app?.getHybridRouting();
  }, [app]);

  const { allLanguage, allWidgetUri } =
    useStaticQuery<Queries.RollingStartNavigationQuery>(graphql`
      query RollingStartNavigation {
        allWidgetUri: allWidgetGroup(filter: { is_page: { eq: true } }) {
          nodes {
            uri
          }
        }

        allLanguage {
          nodes {
            name
            iso_code
            default_lang
            lang
          }
        }
      }
    `);

  const defaultLang: string = useMemo(
    () =>
      (
        allLanguage.nodes.find(({ default_lang }) => !!default_lang)
          ?.iso_code || "fr"
      ).toLowerCase(),
    [allLanguage.nodes]
  );

  const { getConfiguration } = useConfiguration();
  // const { getConfiguration } = useConfiguration({
  //   lang: currentPageContext ? currentPageContext.intl.lang : "fr",
  // });
  // const store = useAppStore();

  // const frontControllers = useMemo(
  //   () => data || selectAll(store.getState()),
  //   [data, selectAll, store]
  // );

  // match controller name
  const getMatchedController = useCallback(
    (p: string): string | undefined => {
      const getLanguages = (): string[] => {
        const _list: string[] = [];
        allLanguage?.nodes?.forEach((langItem) => {
          const isoCode = langItem.iso_code?.toLowerCase() || "";
          if (isoCode) {
            _list.push(isoCode);
          }
        });
        return _list;
      };
      // language list
      const languages = getLanguages() || ["fr"];

      // widget page uri list
      const allWidgetPageUri = new Set(
        allWidgetUri.nodes.map(({ uri }) => uri)
      );
      // regex generator
      const generateRegExp = (
        mainLink: string,
        withLangPrefix: boolean = true
      ): RegExp => {
        // start with
        let reg = "^";
        if (withLangPrefix) {
          reg += `(/(${languages.join("|")})+)?`;
        }

        // main regex
        reg += mainLink;

        // end with
        reg += "/?$";
        return new RegExp(reg);
      };

      //   generate frontControllerMatch
      const checkoutStepsRewrites = [
        getConfiguration("_CART_BASE_URL_", "cart"),
        getConfiguration("_CHECKOUT_CROSS_SELLING_REWRITE_", "cross-selling"),
        getConfiguration("_CHECKOUT_PAYMENT_REWRITE_", "paiement"),
      ].join("|");
      const frontControllerMatch: Record<string, (path: string) => boolean> = {
        Home: (p) => {
          // return new RegExp(`^/(${laguages.join("|")})?/?$`, "ig").test(p);
          return generateRegExp("/").test(p);
        },
        Category: (p) => {
          return (
            generateRegExp(
              `/(${getConfiguration(
                "_CATEGORY_BASE_URL_",
                ""
              )}|delivery)+/([a-z0-9-]+)`
            ).test(p) ||
            generateRegExp(
              `/${getConfiguration(
                "_ALL_CATEGORIES_BASE_URL_",
                "all-categories"
              )}`
            ).test(p)
          );
        },
        Product: (p) =>
          generateRegExp(
            `/(${getConfiguration(
              "_CATEGORY_BASE_URL_",
              ""
            )}|delivery)+/([a-z0-9-]+)/[a-z0-9-]+-([0-9]+)`
          ).test(p),
        Store: (p) =>
          generateRegExp(
            `/(${getConfiguration(
              "_STORE_BASE_URL_",
              "restaurant"
            )}|store)/(([a-z0-9-]+)?/?([a-z0-9-_.]+)?)`
          ).test(p) ||
          generateRegExp(
            `/(${getConfiguration("_STORES_BASE_URL_", "restaurants")}|store)`
          ).test(p) ||
          generateRegExp(
            `/(${getConfiguration(
              "_STORES_BASE_URL_",
              "restaurants"
            )}|store)/(r|s)+/([a-z0-9-]+)?`
          ).test(p) ||
          generateRegExp(
            `/(${getConfiguration(
              "_STORE_BASE_URL_",
              "restaurant"
            )}|store)/([a-z0-9-]+)?/?([a-z0-9-]+)?`
          ).test(p),
        Account: (p) =>
          generateRegExp(
            `/(${getConfiguration("_ACCOUNT_BASE_URL_", "")})/?([a-z0-9-]+)?`
          ).test(p) ||
          generateRegExp(
            `/(${getConfiguration("_LOGOUT_REWRITE_", "")})/?([a-z0-9-]+)?`
          ).test(p),
        Authentification: (p) =>
          generateRegExp(
            `/(${getConfiguration(
              "_AUTHENTIFICATION_BASE_URL_",
              ""
            )})/?([a-z0-9-]+)?`
          ).test(p),
        Reorder: (p) =>
          generateRegExp(
            `/(${getConfiguration("_PAGE_REORDER_BASE_URL_", "")})`
          ).test(p),
        BlankTest: (p) => generateRegExp(`/blank-test`).test(p),
        CheckoutOpc: (p) =>
          generateRegExp(
            `/(${getConfiguration(
              "_CHECKOUT_BASE_URL_",
              "checkout"
            )})\/(${checkoutStepsRewrites})?`
          ).test(p),
        CheckoutConfirmation: (p) =>
          generateRegExp(
            `/(${getConfiguration(
              "_CHECKOUT_BASE_URL_",
              "checkout"
            )})/confirmation`
          ).test(p),
        CheckoutPending: (p) =>
          generateRegExp(
            `/(${getConfiguration("_CHECKOUT_BASE_URL_", "checkout")})/pending`
          ).test(p),
        CheckoutError: (p) =>
          generateRegExp(
            `/(${getConfiguration("_CHECKOUT_BASE_URL_", "checkout")})/error`
          ).test(p),
        SupportCenter: (p) =>
          generateRegExp(
            `/(${getConfiguration(
              "_SUPPORT_CENTER_BASE_URL_",
              "service-client"
            )})/?(([a-z]+)-([0-9]+)|[a-z0-9-]*)`
          ).test(p),
        News: (p) =>
          generateRegExp(
            `/(${getConfiguration("_PAGE_NEWS_BASE_URL_", "actualite")})`
          ).test(p) ||
          generateRegExp(
            `/(${getConfiguration(
              "_PAGE_NEWS_BASE_URL_",
              "actualite"
            )})/(flag)/([a-z0-9-]+)`
          ).test(p) ||
          generateRegExp(
            `/(${getConfiguration(
              "_PAGE_NEWS_BASE_URL_",
              "actualite"
            )})/([a-z0-9-]+)`
          ).test(p),
        Widget: (p) =>
          p.endsWith("/")
            ? allWidgetPageUri.has(p.slice(0, -1)) || allWidgetPageUri.has(p)
            : allWidgetPageUri.has(p + "/") || allWidgetPageUri.has(p),
      };

      return Object.keys(frontControllerMatch).find((k) =>
        frontControllerMatch[k](p)
      );
    },
    [allLanguage?.nodes, allWidgetUri?.nodes, getConfiguration]
  );

  // check is controller has rollinstart as true
  const isRollingStart = useCallback(
    (path: string): boolean => {
      //   get path of the to url
      const parsedUrl = queryString.parseUrl(path);
      const navigateToUrl: string = parsedUrl.url;

      if (/^\//.test(path) && _EXPERIMENTAL_ROLLING_START_ROUTING_) return true;
      // check front controller matching
      const controllerName = getMatchedController(navigateToUrl);
      if (!controllerName) {
        return false;
      }
      const className = controllerName + "Controller";
      // const controller = frontControllers.find(
      //   (c) => c.classname === className
      // );
      const _controller = hybridRoutingCollection?.find(
        (c) => c.get("classname") === className
      );

      // check rolling start
      if (!_controller) {
        return !hybridRoutingCollection?.size(); // par défaut, on n'est pas rolling start
      }
      if (!_controller.get("rollingstart")) {
        return false;
      }
      // check excludes
      const excludes = _controller.get("excludes");
      if (excludes && excludes.length && new Set(excludes).has(navigateToUrl)) {
        return false;
      }
      return true;
    },
    [/*frontControllers, */ getMatchedController]
  );

  const navigate: NavigateType = useCallback(
    (_to, options: any = undefined) => {
      if (typeof _to === "number" || !_to) {
        return intlSushishopNavigate(_to, options);
      }
      let to = GATSBY_CONF_HOME_DISPLAY_OTHER_CONTRY
        ? _to.startsWith(`/${defaultLang}`)
          ? _to
          : `/${defaultLang}${_to.startsWith("/") ? _to : "/" + _to}`
        : _to;
      const re = new RegExp(`^${GATSBY_SOURCE_SUSHISHOP_BASE_URL}`, "i");
      to = to.replace(re, "");
      const isInternal = /^\/(?!\/)/.test(to);

      // Choose navigation type
      const isRS = isRollingStart(to);
      if (
        (isRS ||
          (options && (!!options.modal || !!options.scrollNavigation))) &&
        isInternal
      ) {
        if (options && options.scrollNavigation) {
          return GatsbyNavigate(to, options);
        } else {
          return intlSushishopNavigate(to, options);
        }
      } else {
        if (typeof window !== "undefined") {
          window.location.href = to.startsWith("http")
            ? to
            : GATSBY_SOURCE_SUSHISHOP_BASE_URL + to;
        }
      }
      return Promise.resolve();
    },
    [defaultLang, isRollingStart, app]
  );

  return {
    navigate,
    goBack: () => GatsbyNavigate(-1),
    isRollingStart,
    getMatchedController,
  };
};

export default useRollingStartNavigation;
