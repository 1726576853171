import Typography, {
  ITypographyProps,
} from "../../../Foundation/Typography/Typography";
import React, { ButtonHTMLAttributes } from "react";
import { IDefaultTheme } from "../../../Themes/defaultTheme";
import { RollingStartLinkProps } from "../Link";

export type TypographyLinkProps<D extends any = any> = ITypographyProps &
  RollingStartLinkProps<D> & {
    color?: keyof IDefaultTheme["color"] | (() => string);
    to?: string;
    href?: string;
  };

const DefaultTypographyLink = (props: TypographyLinkProps) => {
  const {
    children,
    color: colorProps,
    type = "button",
    to,
    href = "",
    ...rest
  } = props;
  const route = to || href;
  const javascript_uri = /^javascript:(.*)/.exec(route);
  if (!route || javascript_uri) {
    const { onClick, color, ...buttonRest } = rest as ButtonHTMLAttributes<any>;
    const evalJs = () => {
      eval(javascript_uri ? javascript_uri[1] : "()=>{}");
    };
    return (
      <Typography
        as={"button"}
        {...buttonRest}
        color={colorProps}
        onClick={onClick || evalJs}
        type={type}
      >
        {children}
      </Typography>
    );
  }
  return (
    <Typography
      as={"a"}
      href={href !== "" ? href : to}
      color={colorProps}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default DefaultTypographyLink;
