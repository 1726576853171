import { RollingStartEndpointBuilder } from "..";

interface IStoreLocatorSearch {
  id_object: string;
  name: string;
  object_type: "CITY" | "SHOP" | "STATE" | "POSTCODE";
}
interface IStoreClosure {
  id_store: string;
  closed: boolean;
  timemode?: string;
  from?: string;
  to?: string;
  message?: string;
}

export const storeLocatorSearchEndpoint = (
  builder: RollingStartEndpointBuilder
) => ({
  getStoreLocatorSearch: builder.query<
    IStoreLocatorSearch[],
    { search: string; sync?: string }
  >({
    query: (params) => ({
      url: "/storelocatorsearch",
      method: "GET",
      params,
    }),
  }),
  getStoreClosure: builder.query<IStoreClosure[], void>({
    query: () => ({
      url: "/storeclosure",
      method: "GET",
    }),
  }),
});
