import { EIconFamily, Icon } from "./interface/Icon";
export const classPrefix = "ricon";

export const iconDb: { [_ in string]: Icon } = {
  menu: {
    name: "Menu",
    filename: "Menu",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe000,
  },
  home: {
    name: "Home",
    filename: "Home",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe001,
  },
  search: {
    name: "Search",
    filename: "Search",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe002,
  },
  filters: {
    name: "Filters",
    filename: "Filters",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe003,
  },
  star: {
    name: "Star",
    filename: "Star",
    family: EIconFamily.GEOMETRICAL,
    variant: 24,
    unicode: 0xe004,
  },
  star_fill: {
    name: "Star_fill",
    filename: "Star_fill",
    family: EIconFamily.GEOMETRICAL,
    variant: 24,
    unicode: 0xe005,
  },
  star_fill_half: {
    name: "Star_fill_half",
    filename: "Star_fill_half",
    family: EIconFamily.GEOMETRICAL,
    variant: 24,
    unicode: 0xe006,
  },
  circle_fill: {
    name: "Circle_fill",
    filename: "Circle_fill",
    family: EIconFamily.GEOMETRICAL,
    variant: 24,
    unicode: 0xe007,
  },
  bullet_in_circle: {
    name: "Bullet_in_circle",
    filename: "Bullet_in_circle",
    family: EIconFamily.GEOMETRICAL,
    variant: 24,
  },
  check_mark: {
    name: "Check_mark",
    filename: "Check_mark",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe008,
  },
  plus: {
    name: "Plus",
    filename: "Plus",
    family: EIconFamily.MATHS,
    variant: 24,
    unicode: 0xe009,
  },
  minus: {
    name: "Minus",
    filename: "Minus",
    family: EIconFamily.MATHS,
    variant: 24,
    unicode: 0xe010,
  },
  reorder: {
    name: "Reorder",
    filename: "Reorder",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe011,
  },
  arrow_long_left: {
    name: "Arrow_long_left",
    filename: "Arrow_long_left",
    family: EIconFamily.ARROWS,
    variant: 24,
    unicode: 0xe012,
  },
  arrow_long_right: {
    name: "Arrow_long_right",
    filename: "Arrow_long_right",
    family: EIconFamily.ARROWS,
    variant: 24,
    unicode: 0xe013,
  },
  arrowhead_up: {
    name: "Arrowhead_up",
    filename: "Arrowhead_up",
    family: EIconFamily.ARROWS,
    variant: 24,
    unicode: 0xe014,
  },
  arrowhead_down: {
    name: "Arrowhead_down",
    filename: "Arrowhead_down",
    family: EIconFamily.ARROWS,
    variant: 24,
    unicode: 0xe015,
  },
  caret_down: {
    name: "Caret_down",
    filename: "Caret_down",
    family: EIconFamily.MISC,
    variant: 24,
    unicode: 0xe016,
  },
  clock: {
    name: "Clock",
    filename: "Clock",
    family: EIconFamily.MISC,
    variant: 24,
    unicode: 0xe017,
  },
  pin: {
    name: "Pin",
    filename: "Pin",
    family: EIconFamily.MISC,
    variant: 16,
    unicode: 0xe018,
  },
  pin_outline: {
    name: "Pin_outline",
    filename: "Pin_outline",
    family: EIconFamily.MISC,
    variant: 24,
    unicode: 0xe019,
  },
  arrow_small_left: {
    name: "Arrow_small_left",
    filename: "Arrow_small_left",
    family: EIconFamily.ARROWS,
    variant: 24,
    unicode: 0xe01a,
  },
  arrow_small_right: {
    name: "Arrow_small_right",
    filename: "Arrow_small_right",
    family: EIconFamily.ARROWS,
    variant: 24,
    unicode: 0xe01b,
  },
  address_house: {
    name: "Address_house",
    filename: "Address_house",
    family: EIconFamily.LOCATION,
    variant: 24,
    unicode: 0xe021,
  },
  address_work: {
    name: "Address_work",
    filename: "Address_work",
    family: EIconFamily.LOCATION,
    variant: 24,
    unicode: 0xe023,
  },
  favoris: {
    name: "Favoris",
    filename: "Favoris",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe024,
  },
  favoris_fill: {
    name: "Favoris_fill",
    filename: "Favoris_fill",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe025,
  },
  etoiles: {
    name: "Etoiles",
    filename: "Etoiles",
    family: EIconFamily.MISC,
    variant: 16,
    unicode: 0xe026,
  },
  checkmark_fat: {
    name: "Checkmark_fat",
    filename: "Checkmark_fat",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe027,
  },
  message: {
    name: "Message",
    filename: "Message",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe028,
  },
  cart: {
    name: "Cart",
    filename: "Cart",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe029,
  },
  user: {
    name: "User",
    filename: "User",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe030,
  },
  logo_sushishop: {
    name: "Logo_sushishop",
    filename: "Logo_sushishop",
    family: EIconFamily.IDENTITY,
    variant: 24,
    unicode: 0xe031,
  },
  logo_comein_ichi: {
    name: "Logo_comein_ichi",
    filename: "Logo_comein_ichi",
    family: EIconFamily.IDENTITY,
    variant: 24,
    unicode: 0xe032,
  },
  logo_comein_ni: {
    name: "Logo_comein_ni",
    filename: "Logo_comein_ni",
    family: EIconFamily.IDENTITY,
    variant: 24,
    unicode: 0xe033,
  },
  logo_comein_san: {
    name: "Logo_comein_san",
    filename: "Logo_comein_san",
    family: EIconFamily.IDENTITY,
    variant: 24,
    unicode: 0xe034,
  },
  store: {
    name: "Store",
    filename: "Store",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe035,
  },
  caret_down_16: {
    name: "Caret_down_16",
    filename: "Caret_down_16",
    family: EIconFamily.MISC,
    variant: 16,
    unicode: 0xe036,
  },
  logo_facebook: {
    name: "Logo_facebook",
    filename: "Logo_facebook",
    family: EIconFamily.BRANDS,
    variant: 24,
    unicode: 0xe037,
  },
  logo_instagram: {
    name: "Logo_instagram",
    filename: "Logo_instagram",
    family: EIconFamily.BRANDS,
    variant: 24,
    unicode: 0xe038,
  },
  logo_linkedin: {
    name: "Logo_linkedin",
    filename: "Logo_linkedin",
    family: EIconFamily.BRANDS,
    variant: 24,
    unicode: 0xe039,
  },
  logo_twitter: {
    name: "Logo_twitter",
    filename: "Logo_twitter",
    family: EIconFamily.BRANDS,
    variant: 24,
    unicode: 0xe040,
  },
  cross_close: {
    name: "Cross_close",
    filename: "Cross_close",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe041,
  },
  geoloc: {
    name: "Geoloc",
    filename: "Geoloc",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe042,
  },
  switch_arrow: {
    name: "Switch_arrow",
    filename: "Switch_arrow",
    family: EIconFamily.NAVIGATION,
    variant: 24,
    unicode: 0xe043,
  },
  check_circle_fill: {
    name: "Check_circle_fill",
    filename: "Check_circle_fill",
    family: EIconFamily.MISC,
    variant: 16,
    unicode: 0xe044,
  },
  calendar: {
    name: "Calendar",
    filename: "Calendar",
    family: EIconFamily.MISC,
    variant: 24,
    unicode: 0xe045,
  },
  tag: {
    name: "Tag",
    filename: "Tag",
    family: EIconFamily.MISC,
    variant: 16,
    unicode: 0xe046,
  },
  trash: {
    name: "Trash",
    filename: "Trash",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe046,
  },
  information_circle_fill: {
    name: "Information_circle_fill",
    filename: "Information_circle_fill",
    family: EIconFamily.MISC,
    variant: 16,
    unicode: 0xe047,
  },
  logo_tiktok: {
    name: "Logo_tiktok",
    filename: "Logo_tiktok",
    family: EIconFamily.BRANDS,
    variant: 24,
    unicode: 0xe048,
  },
  click_and_collect: {
    name: "Click_and_collect",
    filename: "Click_and_collect",
    family: EIconFamily.LOCATION,
    variant: 24,
    unicode: 0xe049,
  },
  address_holidays: {
    name: "Address_holidays",
    filename: "Address_holidays",
    family: EIconFamily.LOCATION,
    variant: 24,
    unicode: 0xe050,
  },
  address_friends: {
    name: "Address_friends",
    filename: "Address_friends",
    family: EIconFamily.LOCATION,
    variant: 24,
    unicode: 0xe051,
  },
  address_family: {
    name: "Address_family",
    filename: "Address_family",
    family: EIconFamily.LOCATION,
    variant: 24,
    unicode: 0xe052,
  },
  empty_cart: {
    name: "Empty_cart",
    filename: "Empty_cart",
    family: EIconFamily.NAVIGATION,
    variant: 96,
    unicode: 0xe053,
  },
  instagram: {
    name: "instagram",
    filename: "instagram",
    family: EIconFamily.MISC,
    variant: 24,
    unicode: 0xe054,
  },
  plus_circle: {
    name: "PlusCircle",
    filename: "PlusCircle",
    family: EIconFamily.MATHS,
    variant: 14,
    unicode: 0xe055,
  },
  play: {
    name: "Play",
    filename: "Play",
    family: EIconFamily.MISC,
    variant: 64,
    unicode: 0xe057,
  },
  logo_twitter_x: {
    name: "Logo_twitter_x",
    filename: "Logo_twitter_x",
    family: EIconFamily.BRANDS,
    variant: 24,
    unicode: 0xe058,
  },
  delivery: {
    name: "Delivery",
    filename: "Delivery",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe059,
  },
  customer_service: {
    name: "Customer_Service",
    filename: "Customer_Service",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe05a,
  },
  phone: {
    name: "Phone",
    filename: "Phone",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe05b,
  },
  caret_right_16: {
    name: "Caret_right_16",
    filename: "Caret_right_16",
    family: EIconFamily.MISC,
    variant: 16,
    unicode: 0xe05c,
  },
  edit: {
    name: "Edit",
    filename: "Edit",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe05d,
  },
  download: {
    name: "Download",
    filename: "Download",
    family: EIconFamily.INFORMATION,
    variant: 24,
    unicode: 0xe061,
  },
  check_circle: {
    name: "CheckCircle",
    filename: "CheckCircle",
    family: EIconFamily.INFORMATION,
    variant: 56,
    unicode: 0xe062,
  },
  wallet: {
    name: "Wallet",
    filename: "Wallet",
    family: EIconFamily.FINANCIAL,
    variant: 24,
    unicode: 0xe063,
  },
  gift: {
    name: "Gift",
    filename: "Gift",
    family: EIconFamily.MISC,
    variant: 32,
    unicode: 0xe064,
  },
  copy_paste: {
    name: "CopyPaste",
    filename: "CopyPaste",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe065,
  },
  birthday: {
    name: "Birthday",
    filename: "Birthday",
    family: EIconFamily.BUSINESS,
    variant: 24,
    unicode: 0xe066,
  },
  logo_pinterest: {
    name: "Logo_pinterest",
    filename: "Logo_pinterest",
    family: EIconFamily.BRANDS,
    variant: 24,
    unicode: 0xe068,
  },
};

const list = Object.keys(iconDb) as Array<keyof typeof iconDb>;
export type IconList = keyof typeof iconDb;
